<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          Job Listing
          <span class="d-block text-muted pt-2 font-size-sm">
            Showing latest six month of records
          </span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="col-lg-8 col-xl-6">
            <div class="row align-items-center">
              <div class="col-md-6 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">
                    Search Date
                  </label>
                  <div class="input-icon w-75">
                    <input v-model="fromSearchDate" type="date" class="form-control" id="kt_datatable_search_query" />
                    <span>
                      <i class="flaticon2-calendar-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">Till</label>
                  <div class="input-icon w-75">
                    <input v-model="toSearchDate" type="date" class="form-control" id="kt_datatable_search_query" />
                    <span>
                      <i class="flaticon2-calendar-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-xl-6 mt-5 mt-lg-0">
            <submit-button
              type="button"
              :loading="loading"
              text="Search"
              @button-click="
                searchJobWithinDate(fromSearchDate, toSearchDate);
                loading = true;
              "
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 my-2 mt-md-8 mb-md-0">
            <div class="input-icon">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                @input="searchQuery"
                v-model="searchQueryText"
              />
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <v-client-table :columns="columns" v-model="filteredList" :options="options">
        <template slot="id" slot-scope="props">
          <span class="text-hover-primary" @click="openJobDetail(props.row.id)"> {{ props.row.id }}</span>
          <job-dropdown class="float-right" :id="props.row.id" :job="props.row" />
        </template>

        <template slot="created_at" slot-scope="props">
          {{ formatISO9075(new Date(props.row.created_at)) }}
        </template>

        <div slot="no" slot-scope="props">
          <tr v-for="(trip, index) in props.row.trips" :key="index">
            <td class="nested-td-no-border">{{ trip.type }}</td>
          </tr>
        </div>

        <template slot="address" slot-scope="props">
          <tr v-for="(trip, index) in props.row.trips" :key="index">
            <td class="nested-td-no-border">{{ trip.address }}</td>
          </tr>
        </template>

        <div slot="date" slot-scope="props">
          <tr v-for="(trip, index) in props.row.trips" :key="index">
            <td class="nested-td-no-border">{{ trip.date }} {{ trip.from_time }} - {{ trip.to_time }}</td>
          </tr>
        </div>

        <div slot="trip_status" slot-scope="props">
          <tr v-for="(trip, index) in props.row.trips" :key="index">
            <td class="nested-td-no-border">
              <span class="label label-inline font-weight-bold" :class="getColorByTripStatus(trip.status)">
                {{ trip.status }}
              </span>
            </td>
          </tr>
        </div>
      </v-client-table>
    </div>

    <job-full-details ref="modal" :jobid="jobid" />
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { formatISO, formatISO9075 } from "date-fns";
import { debounce } from "debounce";
import Fuse from "fuse.js";

import { listJobWithinDate } from "@/api/job.api";
import SubmitButton from "@/components/button/SubmitButton";
import JobFullDetails from "@/components/general/JobFullDetails";
import JobDropdown from "@/components/general/JobDropdown";
import { getOmsStatus } from "@/utils/oms-status";

export default {
  name: "ListJob",
  components: { SubmitButton, JobFullDetails, JobDropdown },
  setup(_, { root }) {
    const { getColorByJobStatus, getColorByTripStatus } = getOmsStatus();

    let fullList = [];
    const fromSearchDate = ref(formatISO(new Date(), { representation: "date" }));
    const toSearchDate = ref(formatISO(new Date(), { representation: "date" }));
    const loading = ref(false);
    const filteredList = ref([]);
    const modal = ref(null);
    const searchQueryText = ref("");

    onMounted(() => {
      searchJobWithinDate(fromSearchDate.value, toSearchDate.value);
    });

    const searchJobWithinDate = async (from, to) => {
      searchQueryText.value = "";
      const response = await listJobWithinDate({ from, to });
      fullList = response.data;
      filteredList.value = fullList;
      loading.value = false;
    };

    const columns = ["id", "client", "status", "created_at", "no", "address", "date", "trip_status"];
    const options = {
      headings: {
        id: "Job No.",
        client: "Client",
        status: "Status",
        created_at: "Created",
        no: "Trip #",
        address: "Address",
        date: "Date",
        trip_status: "Status"
      },
      skin: "b-table table table-bordered",
      tabbable: false,
      filterable: false,
      sortable: []
    };

    const searchQuery = debounce(e => {
      if (!e.target.value) {
        filteredList.value = fullList;
      } else {
        const options = {
          minMatchCharLength: 3,
          keys: ["id", "client", "reference_no", "status", "trips.address", "trips.date", "trip.company"]
        };
        const fuse = new Fuse(fullList, options);
        const searchResult = fuse.search(e.target.value);
        filteredList.value = [];
        searchResult.forEach(result => {
          filteredList.value.push(result.item);
        });
      }
    }, 1000);

    const jobid = ref();
    const openJobDetail = job => {
      jobid.value = job;
      root.$nextTick(() => modal.value.$refs["jobdetails"].show());
    };

    return {
      fromSearchDate,
      toSearchDate,
      columns,
      options,
      filteredList,
      searchJobWithinDate,
      openJobDetail,
      loading,
      modal,
      jobid,
      searchQuery,
      searchQueryText,

      getColorByJobStatus,
      getColorByTripStatus,

      formatISO9075
    };
  }
};
</script>

<style scoped>
.nested-td-no-border {
  border: none;
}

table.table ul.dropdown-menu {
  position: relative;
  float: none;
  max-width: 160px;
}
</style>
